<script setup lang="ts">
</script>

<template>
  <div>
    <Header />

    <main class="bg-snow-50">
      <slot />
    </main>

    <LazyFooter />
  </div>
</template>
